





























import Component from 'vue-class-component';
import Vue from 'vue';
import JwlButton from '@/components/JwlButton.vue';
import SisSortableTable from '@/helper/sisSortableTable.vue';
import TableHead from '@/helper/TableHead';
import { LearningGroup } from '@/store/interfaces/LearningGroup';

const IconSearch = () => import('common-modules/src/assets/fa-icons/light/search.svg');

@Component({
  components: {
    SisSortableTable,
    IconSearch,
    JwlButton,
  },
})
export default class SisLearningGroup extends Vue {
  searchTerm = '';
  learningGroups: LearningGroup[] = [];
  tableHeadDefinition: TableHead[] = [
    new TableHead('general.name', 'name'),
    new TableHead(null, null, false, 'link', 'learningGroupEdit', 'clc'),
  ];

  getData (): void {
    this.$store.dispatch('getData', 'learning-group/list')
      .then((data) => {
        this.learningGroups = data;
      });
  }

  get filteredClcs (): LearningGroup[] {
    if (this.searchTerm) {
      return this.learningGroups.filter((learningGroup) => {
        const searchTerm = this.searchTerm.toLowerCase();
        return learningGroup.name ? learningGroup.name.toLowerCase().includes(searchTerm) : false;
      });
    }

    return this.learningGroups;
  }

  mounted (): void {
    this.getData();
  }
}
